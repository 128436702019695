import type { ApiOrdersList } from "@endearhq/service-schema/lib/Models.schema";
import { createContext } from "react";

import { withConsumer } from "../../services/with-consumer";

export interface ConsumerProps {
  ordersCache: Partial<
    Record<string, ElementOf<ApiOrdersList["response"]["200"]["orders"]>>
  >;
  onUpsertOrdersCache: (
    orders: ApiOrdersList["response"]["200"]["orders"],
  ) => void;
}

export const getInitialContext = (): ConsumerProps => ({
  ordersCache: {},
  onUpsertOrdersCache: () => undefined,
});

export const OrdersCacheContext = createContext<ConsumerProps>(
  getInitialContext(),
);

export const { Provider: OrdersCacheProvider, Consumer: OrdersCacheConsumer } =
  OrdersCacheContext;

export const withOrdersCache = withConsumer(OrdersCacheConsumer);
