import type { ComponentType, ConsumerProps as ReactConsumerProps } from "react";

import type { CacheId, ConsumerProps } from "./context";

export type WithCacheBusterIdConsumerProps<K extends CacheId> = Pick<
  ConsumerProps["cache"],
  K
> & {
  onBustCache: ConsumerProps["onBustCache"];
};

export function withCacheBusterId<K extends CacheId>(
  cacheId: K,
  Consumer: ComponentType<ReactConsumerProps<ConsumerProps>>,
) {
  return function withComponent<Props = {}>(
    Component: ComponentType<Props & WithCacheBusterIdConsumerProps<K>>,
  ): ComponentType<Subtract<Props, WithCacheBusterIdConsumerProps<K>>> {
    return (props: Subtract<Props, WithCacheBusterIdConsumerProps<K>>) => (
      <Consumer>
        {(consumerProps) => {
          const pickedProps = {
            [cacheId]: consumerProps.cache[cacheId],
            onBustCache: consumerProps.onBustCache,
          } as WithCacheBusterIdConsumerProps<K>;

          return <Component {...(props as Props)} {...pickedProps} />;
        }}
      </Consumer>
    );
  };
}
