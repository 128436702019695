import { rules, features } from "@endearhq/app-shared";
import type {
  Role,
  SelfAuthorizationKeysDataFragment,
  SelfSubscriptionDataFragment,
  SelfUserDataFragment,
} from "@endearhq/graphql-types";
import { createContext } from "react";

import type { FeatureFlags } from "../../conf";

export interface CurrentUser {
  permissions: {
    user: {
      isEndearAdmin: boolean;
    };
  };
  data: SelfUserDataFragment;
  subscription: SelfSubscriptionDataFragment;
  keys: SelfAuthorizationKeysDataFragment;
  computed_data: {
    identifier: string;
    caption: string | undefined;
    initials: string;
  };
}

export interface ConsumerProps {
  /** @deprecated */
  authToken: string | null;
  status: "loading" | "error" | "ready" | "idle";
  groupId: string | null;
  rules: ReturnType<typeof rules>;
  features: ReturnType<typeof features>;
  featureFlags: FeatureFlags;
  currentUser: CurrentUser | null;
  currentRole: Role | null;
  onInitializeCurrentUser: () => void;
  onGroupIdChange: () => void;
}

export const getInitialContext = (): ConsumerProps => ({
  authToken: null,
  status: "idle",
  groupId: null,
  rules: rules(null),
  features: features(null),
  featureFlags: {},
  currentUser: null,
  currentRole: null,
  onInitializeCurrentUser: () => undefined,
  onGroupIdChange: () => undefined,
});

export const RolesContext = createContext<ConsumerProps>(getInitialContext());
