import { usePrevious } from "@endearhq/app-shared";
import produce from "immer";
import type { ReactNode } from "react";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";

import { RolesContext } from "../Roles";
import type { ConsumerProps } from "./context";
import {
  LookbookItemsCacheConsumer,
  LookbookItemsCacheProvider,
  withLookbookItemsCache,
} from "./context";

interface Props {
  children?: ReactNode;
}

export type LookbookItemsCacheConsumerProps = ConsumerProps;
export type LookbookItemsCacheProps = Props;

export { LookbookItemsCacheConsumer, withLookbookItemsCache };

function LookbookItemsCache(props: Props) {
  const { currentUser } = useContext(RolesContext);

  const [lookbookItemsCache, setLookbookItemsCache] = useState<
    ConsumerProps["lookbookItemsCache"]
  >({});

  const prevCurrentUser = usePrevious(currentUser);
  useEffect(() => {
    if (!currentUser && prevCurrentUser) {
      setLookbookItemsCache({});
    }
  }, [currentUser, prevCurrentUser]);

  const onUpsertLookbookItemsCache = useCallback<
    LookbookItemsCacheConsumerProps["onUpsertLookbookItemsCache"]
  >((sectionId, items) => {
    setLookbookItemsCache((prev) =>
      produce(prev, (draft) => {
        items?.forEach((item) => {
          if (item.section_id) {
            draft[item.section_id] = {
              ...draft[item.section_id],
              [item.id]: item,
            };
          } else {
            draft[sectionId] = {
              ...draft[sectionId],
              [item.id]: undefined,
            };
          }
        });
      }),
    );
  }, []);

  const onDiscardLookbookItemsCache = useCallback<
    LookbookItemsCacheConsumerProps["onDiscardLookbookItemsCache"]
  >((sectionId, deletedItemId) => {
    setLookbookItemsCache((prev) =>
      produce(prev, (draft) => {
        const section = draft[sectionId];

        if (section) {
          draft[sectionId] = Object.fromEntries(
            Object.entries(section).filter(
              ([sectionItemId]) => sectionItemId !== deletedItemId,
            ),
          );
        }
      }),
    );
  }, []);

  const context = useMemo(
    () => ({
      lookbookItemsCache,
      onUpsertLookbookItemsCache,
      onDiscardLookbookItemsCache,
    }),
    [
      lookbookItemsCache,
      onUpsertLookbookItemsCache,
      onDiscardLookbookItemsCache,
    ],
  );

  return (
    <LookbookItemsCacheProvider value={context}>
      {props.children}
    </LookbookItemsCacheProvider>
  );
}

export default LookbookItemsCache;
