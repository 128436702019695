import type { FieldFunctionOptions } from "@apollo/client";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider as Provider,
  defaultDataIdFromObject,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import type {
  GetCampaignByIdQueryVariables,
  GetChannelByIdQueryVariables,
  GetDraftByIdQueryVariables,
  GetTemplateByIdQueryVariables,
  GetTemplatesQueryVariables,
  GetUserByIdQueryVariables,
} from "@endearhq/graphql-types";
import introspectionResult from "@endearhq/graphql-types/esm/introspection-result";
import ApolloLinkTimeout from "apollo-link-timeout";
import type { FC } from "react";

import { DOPPLER_CONFIG, RELEASE, redirectToLogin } from "../../conf";
import { reportError } from "../../services/error-reporter";
import { CodeError, ErrorCode } from "../../services/errors";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    for (const e of graphQLErrors) {
      console.error(e);

      switch (e.extensions?.code) {
        case "BAD_USER_INPUT": {
          // handled by upstream caller
          continue;
        }
        case "USER_REQUIRED": {
          return redirectToLogin();
        }
        case "UNAUTHENTICATED": {
          return redirectToLogin();
        }
        default: {
          const { message, locations, path, originalError } = e;

          reportError(
            new CodeError({
              code: ErrorCode.UnexpectedStatus,
              payload: {
                operation,
                data: { message },
                locations,
                path,
                originalError,
              },
            }),
          );
          break;
        }
      }
    }
  }

  if (networkError) {
    if (networkError?.message.startsWith("Timeout exceeded")) {
      reportError(
        new CodeError({
          code: ErrorCode.Timeout,
          payload: {
            operation,
          },
        }),
      );
    } else {
      reportError(
        new CodeError({
          code: ErrorCode.Offline,
          payload: {
            operation,
          },
        }),
      );
    }
  }

  return;
});

const refreshLink = setContext(async (_, prevContext) => {
  const { headers = {} } = prevContext;

  return {
    headers: {
      ...headers,
      "X-Endear-Version": RELEASE,
      "X-Endear-Env": DOPPLER_CONFIG,
      "X-Endear-Application": "web",
    },
  };
});

const timeoutLink = new ApolloLinkTimeout(30000);

function requestId() {
  return Math.random().toString(36).substring(2, 9);
}

const httpLink = timeoutLink.concat(
  new HttpLink({
    uri: (operation) =>
      `/graphql?name=${operation.operationName}&request-id=${requestId()}`,
    credentials: "same-origin",
  }),
);

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    jitter: true,
    max: 3000,
  },
  attempts: {
    max: 3,
  },
});

type FieldParams<T> = FieldFunctionOptions<Record<string, unknown>, Partial<T>>;

const typePolicies: InMemoryCache["config"]["typePolicies"] = {
  ChannelClient: {
    keyFields: ["id", "contact", "identifier"],
  },
  ChannelParticipantStatus: {
    keyFields: false,
  },
  Query: {
    fields: {
      user(_, params: FieldParams<GetUserByIdQueryVariables>) {
        const { variables, canRead, toReference } = params;

        const ref = toReference({ __typename: "User", id: variables?.id });

        if (ref && canRead(ref)) {
          return ref;
        }

        return undefined;
      },
      draft(_, params: FieldParams<GetDraftByIdQueryVariables>) {
        const { variables, canRead, toReference } = params;

        const id = variables?.id;

        const ref = toReference({ __typename: "Draft", id });

        if (!canRead(ref)) {
          return undefined;
        }

        return ref;
      },
      template(_, params: FieldParams<GetTemplateByIdQueryVariables>) {
        const { variables, canRead, toReference } = params;

        const id = variables?.id;

        const ref = toReference({ __typename: "Template", id });

        if (!canRead(ref)) {
          return undefined;
        }

        return ref;
      },
      templates(_, params: FieldParams<GetTemplatesQueryVariables>) {
        const { variables, canRead, toReference } = params;

        const idsOrId = variables?.ids ?? [];

        const ids = Array.isArray(idsOrId) ? idsOrId : [idsOrId];

        const refs = ids.map((id: string) => {
          return toReference({ __typename: "Template", id });
        });

        if (!refs?.every((ref) => ref && canRead(ref))) {
          return undefined;
        }

        return refs;
      },
      channel(_, params: FieldParams<GetChannelByIdQueryVariables>) {
        const { variables, canRead, toReference } = params;

        const id = variables?.id;

        const ref = toReference({ __typename: "Channel", id });

        if (!canRead(ref)) {
          return undefined;
        }

        return ref;
      },
      campaign(_, params: FieldParams<GetCampaignByIdQueryVariables>) {
        const { variables, canRead, toReference } = params;

        const id = variables?.id;

        const ref = toReference({ __typename: "Campaign", id });

        if (!canRead(ref)) {
          return undefined;
        }

        return ref;
      },
    },
  },
};

export const cache: InMemoryCache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  dataIdFromObject: defaultDataIdFromObject,
  typePolicies,
});

const client = new ApolloClient({
  cache,
  link: from([retryLink, errorLink, refreshLink, httpLink]),
});

const ApolloProvider: FC = ({ children }) => {
  return <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
