import "firebase/auth";
import "firebase/firestore";
import Firebase from "firebase/app";

import { FIREBASE_PROJECT_ID } from "../../conf";

export type FirebaseApp = Firebase.app.App;

export type Firestore = Firebase.firestore.Firestore;

export type FieldValue = Firebase.firestore.FieldValue;

export type QuerySnapshot = Firebase.firestore.QuerySnapshot;

export type Timestamp = Firebase.firestore.Timestamp;
export const Timestamp = Firebase.firestore.Timestamp;

export type DocumentReference = Firebase.firestore.DocumentReference;

export type QueryDocumentSnapshot = Firebase.firestore.QueryDocumentSnapshot;

export const firebaseProjectId = FIREBASE_PROJECT_ID;

export async function signOutOfFirebase(
  firebaseApp: FirebaseApp,
): Promise<true> {
  const auth = firebaseApp.auth();

  if (auth.currentUser) {
    await auth.signOut();
  }

  return true;
}

export function isPermissionError(error: unknown): error is Error {
  return (
    typeof error === "object" &&
    error !== null &&
    typeof (error as Error).message === "string" &&
    (error as Error).message.includes("Missing or insufficient permissions")
  );
}

export enum FBError {
  PermissionDenied = "permission-denied",
  InvalidToken = "auth/invalid-custom-token",
  Offline = "unavailable",
}

interface FirebaseError extends Error {
  code: string;
}

export function isFBError(
  error: unknown,
  code: FBError,
): error is FirebaseError {
  return (
    typeof error === "object" &&
    error !== null &&
    typeof (error as FirebaseError).code === "string" &&
    (error as FirebaseError).code === code
  );
}
