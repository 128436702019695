import type { Location } from "react-router-dom";

export enum Event {
  InitialPageLoad = "Initial Page Load",
  Polyfill = "Polyfill",
  SignIn = "Sign In",
}

export function trackPageView(location: Location) {
  const [, name] = location.pathname.split("/");

  if (name && typeof window.analytics !== "undefined") {
    window.analytics.page(
      name,
      {},
      {
        integrations: {
          Intercom: { hideDefaultLauncher: true },
        },
      },
    );
  }
}
