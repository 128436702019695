import { usePrevious } from "@endearhq/app-shared";
import produce from "immer";
import type { ReactNode } from "react";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";

import { RolesContext } from "../Roles";
import type { ConsumerProps } from "./context";
import {
  LookbooksCacheConsumer,
  LookbooksCacheProvider,
  withLookbooksCache,
} from "./context";

interface Props {
  children?: ReactNode;
}

export type LookbooksCacheConsumerProps = ConsumerProps;
export type LookbooksCacheProps = Props;

export { LookbooksCacheConsumer, withLookbooksCache };

function LookbooksCache(props: Props) {
  const { currentUser } = useContext(RolesContext);

  const [lookbooksCache, setLookbooksCache] = useState<
    ConsumerProps["lookbooksCache"]
  >({});

  const prevCurrentUser = usePrevious(currentUser);
  useEffect(() => {
    if (!currentUser && prevCurrentUser) {
      setLookbooksCache({});
    }
  }, [currentUser, prevCurrentUser]);

  const onUpsertLookbooksCache = useCallback<
    LookbooksCacheConsumerProps["onUpsertLookbooksCache"]
  >((lookbooks) => {
    setLookbooksCache((prev) =>
      produce(prev, (draft) => {
        lookbooks?.forEach((lookbook) => {
          const existingLookbook = draft[lookbook.id];

          if (
            !existingLookbook ||
            existingLookbook.updated_at <= lookbook.updated_at
          ) {
            draft[lookbook.id] = lookbook;
          }
        });
      }),
    );
  }, []);

  const context = useMemo(
    () => ({
      lookbooksCache,
      onUpsertLookbooksCache,
    }),
    [lookbooksCache, onUpsertLookbooksCache],
  );

  return (
    <LookbooksCacheProvider value={context}>
      {props.children}
    </LookbooksCacheProvider>
  );
}

export default LookbooksCache;
