import { memo } from "react";
import { useTranslation } from "react-i18next";

import { ASSETS_URL } from "../../conf";
import type { ErrorComponentProps } from "../../providers/ErrorBoundary";
import { PermissionRequiredError } from "../../services/errors";
import { useNavigate } from "../../services/router-utils";

const ErrorComponent = memo<ErrorComponentProps>((props) => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();

  const onReloadWindow = () => {
    window.location.reload();
  };

  const { title, body, onAction, label, image } =
    props.error instanceof PermissionRequiredError
      ? {
          title: t("Error.permission-denied"),
          body: t("Error.you-do-not-have-perm"),
          onAction: () => navigate("/"),
          label: t("Error.take-me-home"),
          image: `${ASSETS_URL}/ui/lock.svg`,
        }
      : {
          title: t("Error.oops"),
          body: t("Error.something-went-wrong"),
          onAction: () => onReloadWindow(),
          label: t("Error.reload-the-page"),
          image: `${ASSETS_URL}/ui/shoe.svg`,
        };

  return (
    <div className="flex flex-column flex-nowrap items-center justify-center pv5">
      <img src={image} width={300} height={300} alt="" />
      <div className="mb4 tc mw6">
        <h1 className="f2 fw6 lh-title mb2">{title}</h1>
        <p className="f3 lh-copy">{body}</p>
      </div>
      <button
        type="button"
        className="Polaris-Button Polaris-Button--primary"
        tabIndex={0}
        onClick={() => onAction()}>
        {label}
      </button>
    </div>
  );
});

export default ErrorComponent;
