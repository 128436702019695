import type { ComponentType, ConsumerProps, ReactNode } from "react";

export function withConsumer<ContextConsumerProps = {}>(
  Consumer: ComponentType<
    ConsumerProps<ContextConsumerProps & { children?: ReactNode }>
  >,
) {
  return function withComponent<Props = {}>(
    Component: ComponentType<Props & ContextConsumerProps>,
  ): ComponentType<Subtract<Props, ContextConsumerProps>> {
    return (props: Subtract<Props, ContextConsumerProps>) => (
      <Consumer>
        {(consumerProps) => (
          <Component {...(props as Props)} {...consumerProps} />
        )}
      </Consumer>
    );
  };
}
