import {
  rules as getRules,
  features as getFeatures,
} from "@endearhq/app-shared";
import type { SelfUserDataFragment } from "@endearhq/graphql-types";
import {
  Role,
  UserRole,
  useSelfLazyQuery,
  useGetFeatureFlagsByKeyLazyQuery,
} from "@endearhq/graphql-types";
import { getJSON } from "js-cookie";
import type { ReactNode } from "react";
import { useCallback, useMemo, useEffect } from "react";

import { FEATURE_FLAGS } from "../../conf";
import type { CurrentUser, ConsumerProps } from "./context";
import { RolesContext } from "./context";

export type { CurrentUser };

interface Props {
  children: ReactNode;
}

export type FeatureType = "starter" | "growth" | "professional" | "enterprise";

export type RolesConsumerProps = ConsumerProps;
export type RolesProps = Props;

export { RolesContext };

function getSignedCookie(name: string): Record<string, string> | null {
  try {
    const cookie = getJSON()[name];
    const parsed = JSON.parse(atob(cookie.split(".")[0]));
    return parsed ?? null;
  } catch {
    return null;
  }
}

function getUnsignedCookie(name: string): Record<string, string> | null {
  try {
    const cookie = getJSON()[name];
    const parsed = JSON.parse(atob(cookie));
    return parsed ?? null;
  } catch {
    return null;
  }
}

const currentGroupId =
  (getSignedCookie("__team") ?? getUnsignedCookie("__team"))?.teamId ?? null;

function Roles({ children }: Props) {
  const [self, selfOpts] = useSelfLazyQuery({ fetchPolicy: "network-only" });

  const user = selfOpts.data?.self ?? null;
  const subscription = selfOpts.data?.subscription ?? null;

  const [getFeatureFlags, getFeatureFlagsOpts] =
    useGetFeatureFlagsByKeyLazyQuery();

  useEffect(() => {
    if (!getFeatureFlagsOpts.called) {
      getFeatureFlags({ variables: { keys: FEATURE_FLAGS } });
    }
  }, [getFeatureFlags, getFeatureFlagsOpts.called]);

  const onInitializeCurrentUser = useCallback(() => {
    self({ variables: {} });
  }, [self]);

  const onGroupIdChange = useCallback(() => {
    window.location.reload();
  }, []);

  const rules = useMemo(() => getRules(user), [user]);

  const features = useMemo(
    () => getFeatures(subscription?.plan.feature_type),
    [subscription],
  );

  const status = useMemo(() => {
    if (selfOpts.data) {
      return "ready" as const;
    }

    if (selfOpts.loading) {
      return "loading" as const;
    }

    if (selfOpts.error) {
      return "error" as const;
    }

    return "idle" as const;
  }, [selfOpts]);

  const context = useMemo<ConsumerProps>(() => {
    const currentRole = getCurrentRole(user);

    const currentUser: CurrentUser | null = selfOpts.data
      ? {
          data: selfOpts.data.self,
          keys: selfOpts.data.authorizationKeys,
          subscription: selfOpts.data.subscription,
          computed_data: {
            identifier: selfOpts.data.self.identifier,
            caption: selfOpts.data.self.account.caption ?? undefined,
            initials: selfOpts.data.self.identifier[0],
          },
          permissions: {
            user: {
              isEndearAdmin: selfOpts.data.self.roles.includes(
                UserRole.ArthurAdmin,
              ),
            },
          },
        }
      : null;

    const currentFeatureFlags = parseFeatureFlagObjects(
      getFeatureFlagsOpts.data?.featureFlags,
    );

    return {
      status,
      rules,
      features,
      featureFlags: currentFeatureFlags,
      authToken: "TOKEN",
      groupId: currentGroupId,
      currentUser,
      currentRole,
      onInitializeCurrentUser,
      onGroupIdChange,
    };
  }, [
    user,
    selfOpts.data,
    status,
    rules,
    features,
    getFeatureFlagsOpts.data,
    onInitializeCurrentUser,
    onGroupIdChange,
  ]);

  return (
    <RolesContext.Provider value={context}>{children}</RolesContext.Provider>
  );
}

export default Roles;

function getCurrentRole(user: SelfUserDataFragment | null): Role | null {
  if (!user) {
    return null;
  }

  if (user.roles.includes(UserRole.Admin)) {
    return Role.Admin;
  }

  if (user.roles.includes(UserRole.Manager)) {
    return Role.Manager;
  }

  return Role.User;
}

function parseFeatureFlagObjects(
  featureFlagData: { key: string; active: boolean }[] | null | undefined,
) {
  const currentFeatureFlags: Record<string, boolean> = {};

  featureFlagData?.forEach(({ key, active }) => {
    const [flag, _env, _brand] = key.split(":");
    currentFeatureFlags[flag] = active;
  });

  return currentFeatureFlags;
}
