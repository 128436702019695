import type { ComponentType } from "react";
import { Component } from "react";

export interface ErrorComponentProps {
  error: unknown;
  onClearError(): void;
}

export interface ErrorBoundaryProps {
  reportError: (error: unknown, errorInfo: unknown) => void;
  ErrorComponent: ComponentType<ErrorComponentProps>;
}

type State = ReturnType<typeof getState>;

const getState = () => ({ error: null as unknown | null });

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  State
> {
  state = getState();

  onClearError = () => this.setState(() => ({ error: null }));

  componentDidCatch(error: unknown, errorInfo: unknown) {
    this.setState(() => ({ error }));

    this.props.reportError(error, errorInfo);
  }

  render() {
    const { ErrorComponent } = this.props;

    return this.state.error ? (
      <ErrorComponent
        error={this.state.error}
        onClearError={this.onClearError}
      />
    ) : (
      this.props.children
    );
  }
}
