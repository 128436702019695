import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DOPPLER_CONFIG } from "../../conf";
import {
  errorReporter,
  withScope,
  captureException,
} from "../../services/error-reporter";
import type { AppError } from "../../services/errors";
import { CodeError, ClientError, ErrorCode } from "../../services/errors";
import type { ConsumerProps, ErrorToDisplay } from "./context";
import {
  ErrorsToDisplayContext,
  ErrorsToDisplayProvider,
  withErrorsToDisplay,
} from "./context";

export type { ErrorToDisplay };

interface Props {}

export type ErrorsToDisplayConsumerProps = ConsumerProps;
export type DrrorsToDisplayProps = Props;

export { ErrorsToDisplayContext, withErrorsToDisplay };

// const getUniqueIDForError = () => {
//   return `_${Math.random().toString(36).substr(2, 9)}`;
// };

const ErrorsToDisplay = memo<Props>((props) => {
  const [errors, setErrors] = useState<ErrorToDisplay[]>([]);

  const onDismiss = useCallback((id: string) => {
    setErrors((prev) => prev.filter((error) => error.id !== id));
  }, []);

  useEffect(() => {
    function handleEvent(e: Event) {
      if (e instanceof CustomEvent) {
        const error = e.detail.error as AppError;

        if (
          (error instanceof CodeError && error.capture) ||
          (error instanceof ClientError && error.capture) ||
          error instanceof Error
        ) {
          if (
            DOPPLER_CONFIG === "dev" ||
            DOPPLER_CONFIG === "dev_tunnel" ||
            DOPPLER_CONFIG === "dev_preview" ||
            DOPPLER_CONFIG === "prd_loc"
          ) {
            console.error(error);
          }

          withScope((scope) => {
            if (error.payload) {
              if (typeof error.payload !== "object" || !error.payload) {
                scope.setExtra("payload", error.payload);
              } else {
                scope.setExtras(error.payload);
              }
            }

            captureException(error);
          });
        }

        if (error instanceof CodeError && error.code !== ErrorCode.Offline) {
          // setErrors((prev) => [
          //   ...prev,
          //   {
          //     id: getUniqueIDForError(),
          //     content: `${error.heading}: ${error.body}`,
          //   },
          // ]);
        }
      }
    }

    errorReporter.addEventListener("appError", handleEvent);

    return () => {
      errorReporter.removeEventListener("appError", handleEvent);
    };
  }, []);

  const context = useMemo(
    () => ({
      errors: errors,
      onDismiss,
    }),
    [onDismiss, errors],
  );

  return (
    <ErrorsToDisplayProvider value={context}>
      {props.children}
    </ErrorsToDisplayProvider>
  );
});

export default ErrorsToDisplay;
