import "@endearhq/polaris-extended/esm/index.css";
import "@endearhq/polaris/build/esm/styles.css";
import "../../styles/styles.scss";
import "../../styles/tachyons.scss";

import { BrowserRouter } from "react-router-dom";

import FirebaseProvider from "../../dom-providers/Firebase";
import ApolloProvider from "../../providers/Apollo";
import CacheBusterProvider from "../../providers/CacheBuster";
import ErrorBoundary from "../../providers/ErrorBoundary";
import ErrorsToDisplayProvider from "../../providers/ErrorsToDisplay";
import LookbookItemsCacheProvider from "../../providers/LookbookItemsCache";
import LookbooksCacheProvider from "../../providers/LookbooksCache";
import OrdersCacheProvider from "../../providers/OrdersCache";
import RolesProvider from "../../providers/Roles";
import { reportBoundaryError } from "../../services/error-reporter";
import App from "./App";
import ErrorComponent from "./Error";

if (window.top === window.self) {
  document.documentElement.style.setProperty(
    "--app-navigation-height",
    "5.6rem",
  );
} else {
  document.documentElement.style.setProperty("--app-navigation-height", "0px");
}

export default function Root() {
  return (
    <ErrorBoundary
      reportError={reportBoundaryError}
      ErrorComponent={ErrorComponent}>
      <ErrorsToDisplayProvider>
        <ApolloProvider>
          <RolesProvider>
            <FirebaseProvider>
              <CacheBusterProvider>
                <OrdersCacheProvider>
                  <LookbookItemsCacheProvider>
                    <LookbooksCacheProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </LookbooksCacheProvider>
                  </LookbookItemsCacheProvider>
                </OrdersCacheProvider>
              </CacheBusterProvider>
            </FirebaseProvider>
          </RolesProvider>
        </ApolloProvider>
      </ErrorsToDisplayProvider>
    </ErrorBoundary>
  );
}
