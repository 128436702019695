import classNames from "classnames";
import type { ReactNode } from "react";
import { forwardRef, memo } from "react";
import type { LinkProps } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
    console.log("CHILD", "ONCLICK", props.to);

    if (window.top !== window.self && !e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      window.parent.postMessage(
        {
          type: "navigate",
          to: props.to,
          options: { replace: false },
          trigger: "link",
        },
        "*",
      );
    }
  }

  return <ReactRouterLink {...props} ref={ref} onClick={onClick} />;
});

export interface LinkComponentProps {
  url: string;
  className?: string;
  children?: ReactNode;
  external?: boolean;
  download?: string | boolean;
  [key: string]: unknown;
}

const LinkComponent = memo<LinkComponentProps>((props) => {
  const {
    url,
    external,
    children,
    className,
    target: _target,
    rel: _rel,
    ref: _ref,
    defaultValue: _defaultValue,
    ...restOfProps
  } = props;

  if (url.startsWith("http")) {
    if (external) {
      return (
        <a
          {...restOfProps}
          className={classNames("JoinArthur--Link", className)}
          target="_blank"
          rel="noopener noreferrer nofollow"
          href={url}>
          {children}
        </a>
      );
    }

    return (
      <a
        {...restOfProps}
        className={classNames("JoinArthur--Link", className)}
        href={url}>
        {children}
      </a>
    );
  }

  return (
    <Link
      {...restOfProps}
      to={url}
      className={classNames("JoinArthur--Link", className)}>
      {children}
    </Link>
  );
});

export default LinkComponent;
