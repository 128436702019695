import type { ApiLookbooksList } from "@endearhq/service-schema/lib/Models.schema";
import { createContext } from "react";

import { withConsumer } from "../../services/with-consumer";

export interface ConsumerProps {
  lookbooksCache: Partial<{
    [k: string]: ElementOf<ApiLookbooksList["response"]["200"]["lookbooks"]>;
  }>;
  onUpsertLookbooksCache: (
    lookbooks: ApiLookbooksList["response"]["200"]["lookbooks"],
  ) => void;
}

export const getInitialContext = (): ConsumerProps => ({
  lookbooksCache: {},
  onUpsertLookbooksCache: () => undefined,
});

export const LookbooksCacheContext = createContext<ConsumerProps>(
  getInitialContext(),
);

export const {
  Provider: LookbooksCacheProvider,
  Consumer: LookbooksCacheConsumer,
} = LookbooksCacheContext;

export const withLookbooksCache = withConsumer(LookbooksCacheConsumer);
