import type { AppProviderProps } from "@endearhq/polaris";

import { assets } from "./conf";

export const baseTheme: NonNullable<AppProviderProps["theme"]> = {
  logo: {
    width: 35,
    topBarSource: assets.mark,
    contextualSaveBarSource: assets.mark,
    url: "/",
    accessibilityLabel: "Endear",
  },
};
