import type { ReactNode } from "react";
import { memo, useContext, useEffect, useMemo, useState } from "react";

import { RolesContext } from "../../providers/Roles";
import { captureException } from "../../services/error-reporter";
import { toError } from "../../services/errors";
import type { FirebaseApp, Firestore } from "../../services/firebase";
import { firebaseProjectId } from "../../services/firebase";
import type { ConsumerProps } from "./context";
import { FirebaseContext } from "./context";

type Props = {
  children: ReactNode;
};

export { FirebaseContext };

export type FirebaseConsumerProps = ConsumerProps;
export type FirebaseProps = Props;

type FirebaseImport = Resolve<ReturnType<typeof importFirebase>>;

let FirebaseAppImport: FirebaseImport | undefined;

export const [importFirebase, importFirebaseAuth, importFirebaseFirestore] = [
  async () => import("firebase/app").then((m) => m.default),
  async () => import("firebase/auth").then((m) => m.default),
  async () => import("firebase/firestore").then((m) => m.default),
];

interface FirebaseState {
  app: {
    firebaseApp: FirebaseApp;
    firestore: Firestore;
  } | null;
}

const Firebase = memo<Props>((props) => {
  const { children } = props;

  const [firebase, setFirebase] = useState<FirebaseState>({ app: null });

  const { currentUser } = useContext(RolesContext);

  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    if (isImporting || firebase.app || !currentUser) {
      return;
    }

    const {
      keys: { FIREBASE_WEB_API_KEY: apiKey, FIREBASE_AUTH_TOKEN },
    } = currentUser;

    setIsImporting(true);

    async function handleImport() {
      try {
        if (!FirebaseAppImport) {
          const _Firebase = await importFirebase();
          await Promise.all([importFirebaseAuth(), importFirebaseFirestore()]);

          FirebaseAppImport = _Firebase;
        }

        if (!FirebaseAppImport) {
          throw new Error("unable to load firebase");
        }

        let firebaseApp: FirebaseApp;

        const [defaultApp] = FirebaseAppImport.apps;
        if (defaultApp) {
          firebaseApp = defaultApp;
        } else {
          firebaseApp = FirebaseAppImport.initializeApp({
            projectId: firebaseProjectId,
            apiKey,
            authDomain: `${firebaseProjectId}.firebaseapp.com`,
            databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
          });
        }

        const auth = firebaseApp.auth();

        let firebaseTokenValid: boolean;
        let firebaseTokenError: unknown;
        try {
          await auth.setPersistence(
            FirebaseAppImport.auth.Auth.Persistence.SESSION,
          );
          await auth.signInWithCustomToken(FIREBASE_AUTH_TOKEN);

          firebaseTokenValid = true;
        } catch (err) {
          firebaseTokenError = err;
          firebaseTokenValid = false;
        }

        if (firebaseTokenError) {
          captureException(toError(firebaseTokenError));
        }

        if (firebaseTokenValid) {
          setFirebase({
            app: {
              firebaseApp,
              firestore: firebaseApp.firestore(),
            },
          });
        }
      } finally {
        setIsImporting(false);
      }
    }

    handleImport();
  }, [isImporting, firebase.app, currentUser, setFirebase]);

  const context = useMemo<FirebaseConsumerProps>(() => {
    return {
      firestore: firebase.app?.firestore ?? null,
    };
  }, [firebase.app]);

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
});

export default Firebase;
